import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import Chat from '../Chat/Chat'
import Header from 'components/Header'
import { styleByScreenSize } from './configs'
import { WebSocketsSharedConsumer } from 'integrations/WebSockets'

import Popover from 'components/Popover'
import EndConversationModal from '../../EndCoversationModal'
import FeedbackModal from '../../FeedbackModal'
import { EndConversationModalContext } from 'context/EndConversationContext'
import { FeedbackModalContext } from 'context/FeedbackModalContext'
import classes from './styles.module.scss'
import 'app/App.css'
import { BotPersonality, SettingsType } from 'models/SettingsType'
import { DeviceType } from 'models/DeviceType'
import { MessageType } from 'models/MessageType'
import { PersistentMenuType } from 'models/PersistentMenuType'

interface Props {
  screenSize: DeviceType
  device: DeviceType
  isMobile: boolean
  settings: SettingsType
  persistentMenu: PersistentMenuType
  parentURL: string
  messages: MessageType[]
  isProcessingGPTMessage: boolean
  setIsProcessingGPTMessage: (isProcessingGPTMessage: boolean) => void
  GPTMessagesQueue: MessageType[]
  resetGPTMessagesQueue: () => void
  isHiddenWidget: boolean
  textDisabled: boolean
  inputTogglerValue: string
  toggleWidget: () => void
  resizeWidget: () => void
  updateMessages: () => void
  isFullScreenWidget: boolean
  handleHiddenWidget: () => void
  firstUnreadMessageId: string
  setFirstUnreadMessageId: (a: boolean | null) => void
  handleSoundNotification: () => void
  isSoundNotification: boolean
  isEmbedded: boolean
  botPersonality: BotPersonality
  setBotPersonality: BotPersonality
  resetChat: () => void
}

const Container: React.FC<Props> = props => {
  const {
    device,
    isMobile,
    settings,
    persistentMenu,
    parentURL,
    messages,
    isProcessingGPTMessage,
    GPTMessagesQueue,
    resetGPTMessagesQueue,
    setIsProcessingGPTMessage,
    isHiddenWidget,
    textDisabled,
    inputTogglerValue,
    toggleWidget,
    resizeWidget,
    updateMessages,
    isFullScreenWidget,
    handleHiddenWidget,
    firstUnreadMessageId,
    setFirstUnreadMessageId,
    handleSoundNotification,
    isSoundNotification,
    screenSize,
    isEmbedded,
    botPersonality,
    resetChat
  } = props
  const [openPopover, setOpenPopover] = useState(false)
  const { isOpenEndConversationModal, setIsOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { isOpenFeedbackModal, setIsOpenFeedbackModal } = useContext(FeedbackModalContext)
  const popoverRef = useRef(null)
  const parentRef = useRef(null)

  const containerStyle = useMemo(() => {
    return device &&
      styleByScreenSize(isFullScreenWidget, device.width, device.height, isMobile, screenSize, isEmbedded)
  }, [isFullScreenWidget, device, isMobile, screenSize, isEmbedded])

  useEffect(() => {
    return () => {
      setFirstUnreadMessageId(null)
      setIsOpenEndConversationModal(false)
      setIsOpenFeedbackModal(false)
    }
  }, [])

  const handleOpenPopover = () => {
    setOpenPopover(!openPopover)
  }

  const renderModal = () => {
    if (isOpenEndConversationModal) {
      return <EndConversationModal settings={settings} toggleWidget={toggleWidget} />
    } else if (isOpenFeedbackModal) {
      return <FeedbackModal settings={settings} toggleWidget={toggleWidget} />
    }
  }

  return (
    <WebSocketsSharedConsumer>
      {value => (
        <div className={isEmbedded ?
          classes.containerEmbedded : classes.container} style={containerStyle}>
          {settings && (
            <Header
              header={settings.widgetName}
              isFullScreenWidget={isFullScreenWidget}
              botPersonality={botPersonality}
              popoverRef={popoverRef}
              parentRef={parentRef}
              openPopover={openPopover}
              handleHiddenWidget={handleHiddenWidget}
              handleOpenPopover={handleOpenPopover}
              isEmbedded={isEmbedded}
            />
          )}
          {openPopover && (
            <Popover
              settings={settings}
              isMobile={isMobile}
              isFullScreenWidget={isFullScreenWidget}
              resizeWidget={resizeWidget}
              anchorEl={popoverRef}
              parentEl={parentRef}
              isSoundNotification={isSoundNotification}
              onClose={() => setOpenPopover(false)}
              handleSoundNotification={handleSoundNotification}
              resetChat={resetChat}
            />
          )}
          {value && (
            <Chat
              screenSize={screenSize}
              device={device}
              isMobile={isMobile && !isEmbedded}
              messages={messages}
              isProcessingGPTMessage={isProcessingGPTMessage}
              setIsProcessingGPTMessage={setIsProcessingGPTMessage}
              GPTMessagesQueue={GPTMessagesQueue}
              resetGPTMessagesQueue={resetGPTMessagesQueue}
              isHiddenWidget={isHiddenWidget}
              settings={settings}
              persistentMenu={persistentMenu}
              parentURL={parentURL}
              startData={value.startData}
              postMessage={value.postMessage}
              textDisabled={textDisabled}
              inputTogglerValue={inputTogglerValue}
              updateMessages={updateMessages}
              chatId={value.chatId}
              showTyping={value.showTyping}
              updateTyping={value.updateTyping}
              isFullScreenWidget={isFullScreenWidget}
              initializeConversation={value.initializeConversation}
              getMoreChatHistory={value.getMoreChatHistory}
              firstUnreadMessageId={firstUnreadMessageId}
              isEmbedded={isEmbedded}
              botPersonality={botPersonality}
            />
          )}
          {renderModal()}
        </div>
      )}
    </WebSocketsSharedConsumer>
  )
}

export default Container
