import React, { useContext } from 'react'
import { MinusIcon, TreeDotsIcon } from 'icons/Icons'
import { EndConversationModalContext } from 'context/EndConversationContext'
import { FeedbackModalContext } from 'context/FeedbackModalContext'
import * as S from './Header.style'
import { BotPersonality } from '../../models/SettingsType.d'

interface Props {
  parentRef: any
  popoverRef: any
  header: string
  isFullScreenWidget: boolean
  botPersonality: BotPersonality
  openPopover: boolean
  handleHiddenWidget: () => void
  handleOpenPopover: () => void
  isEmbedded: boolean
}

export const Header: React.FC<Props> = ({
  parentRef,
  popoverRef,
  header,
  isFullScreenWidget,
  botPersonality,
  handleHiddenWidget,
  handleOpenPopover,
  openPopover,
  isEmbedded,
}) => {
  const { isOpenEndConversationModal } = useContext(EndConversationModalContext)
  const { isOpenFeedbackModal } = useContext(FeedbackModalContext)
  const preventFocus = isOpenEndConversationModal || isOpenFeedbackModal

  const EXPORT_BOT_NAME = 'Anxiety Coach'
  const COMPANION_BOT_NAME = 'Assistant'

  const BOT_NAME = botPersonality == BotPersonality.EXPERT ? EXPORT_BOT_NAME : COMPANION_BOT_NAME
  const HEADER_COLOR = botPersonality == BotPersonality.EXPERT ? '#355C7D' : '#00C19F'
  const AIME_HEADER_IMAGE = `${process.env.PUBLIC_URL}/aime_header_image.png`

  const queryParams = new URLSearchParams(window.location.search)
  const screenTopPadding = parseInt(queryParams.get('screenTopPadding') || '35', 10)

  return (
    <S.Header ref={parentRef} color={HEADER_COLOR} isFullScreenWidget={isFullScreenWidget}
      logoMarginTop={screenTopPadding}
    >
      <S.NameWrap>
        <S.Logo src={AIME_HEADER_IMAGE} marginTop={screenTopPadding} alt={header} />
        <S.Name
          key={BOT_NAME}
          tabIndex={preventFocus ? -1 : 0}
        >
          {BOT_NAME}
        </S.Name>
      </S.NameWrap>
      {isEmbedded && (
        <S.ButtonsWrap marginTop={screenTopPadding}>
          <S.Button
            id="three-dots-widget-icon"
            onClick={handleOpenPopover}
            ref={popoverRef}
            aria-label={`${openPopover ? 'close' : 'open'} popup`}
            tabIndex={preventFocus ? -1 : 0}>
            <TreeDotsIcon />
          </S.Button>
        </S.ButtonsWrap>
      )}
      {!isEmbedded && (
        <S.ButtonsWrap>
          <S.Button
            id="three-dots-widget-icon"
            onClick={handleOpenPopover}
            ref={popoverRef}
            aria-label={`${openPopover ? 'close' : 'open'} popup`}
            tabIndex={preventFocus ? -1 : 0}>
            <TreeDotsIcon />
          </S.Button>
          <S.Button
            onClick={handleHiddenWidget}
            id="wrap-widget-icon"
            aria-label="wrap widget"
            tabIndex={preventFocus ? -1 : 0}>
            <MinusIcon />
          </S.Button>
        </S.ButtonsWrap>
      )}
    </S.Header>
  )
}
