import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import xss from 'xss'

import { BotPersonality, SettingsType } from 'models/SettingsType.d'
import { PersistentMenuType } from 'models/PersistentMenuType'
import { DeviceType } from 'models/DeviceType'
import { isEmptyString } from 'actions/helpers'
import { CompanionSendIcon, ExpertSendIcon } from 'icons/Icons'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { INPUT_OFF } from 'constants/actionsType'
import { MAX_INPUT_LENGTH } from 'constants/index'
import { FeedbackModalContext } from 'context/FeedbackModalContext'

import * as S from './UserInput.style'

interface Props {
  textDisabled: boolean
  inputTogglerValue: string
  settings: SettingsType
  persistentMenu: PersistentMenuType
  isFullScreenWidget: boolean
  isRtl: boolean
  chatId: number
  device: DeviceType
  showIframe: boolean
  postMessage: (m: any) => void
  startData: any
  screenSize: DeviceType
  disableInput: boolean
  isEmbedded: boolean
  botPersonality: BotPersonality
}

export const UserInput: React.FC<Props> = ({
  textDisabled,
  inputTogglerValue,
  settings,
  isRtl,
  chatId,
  postMessage,
  startData,
  disableInput,
  botPersonality
}) => {
  const [text, setText] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const preventFocus = usePreventFocus()
  const { isOpenFeedbackModal } = useContext(FeedbackModalContext)
  const isHiddenTextInput = useMemo(() => inputTogglerValue === INPUT_OFF, [inputTogglerValue])

  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px` // 120px max height
    }
  }, [])

  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const isPrintableKey = event.key.length === 1 && !event.metaKey && !event.ctrlKey

      if (document.activeElement !== textareaRef.current && isPrintableKey && !isOpenFeedbackModal) {
        textareaRef.current?.focus()
      }
    },
    [isOpenFeedbackModal],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isHiddenTextInput) return
    setText(e.target.value.slice(0, MAX_INPUT_LENGTH))
    adjustTextareaHeight()
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !disableInput) {
      e.preventDefault()
      handleSend()
    }
  }

  const handleSend = () => {
    if (isHiddenTextInput) return

    if (!isEmptyString(text)) {
      const message = {
        from: {
          id: chatId,
          name: startData.name || 'Widget user',
          email: startData.email || '',
        },
        type: 'text',
        isRtl: !!isRtl,
        text: xss(text),
      }
      postMessage(message)
      setText('')

      // Reset textarea height after sending
      if (textareaRef.current) {
        textareaRef.current.style.height = '54px'
      }
    }
  }

  return (
    <S.UserInputContainer textDisabled={textDisabled}>
      <S.TextArea
        placeholder={settings?.inputPlaceholder}
        dir={isRtl ? 'rtl' : 'ltr'}
        id="messaging-user-input"
        autoComplete="off"
        value={text}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        tabIndex={preventFocus ? -1 : 0}
        data-testid={'input'}
        ref={textareaRef}
        isHiddenTextInput={isHiddenTextInput}
        rows={1}
      />
      <S.InputLengthIndicator length={text.length} data-testid={'indicator'}>
        {text.length}/{MAX_INPUT_LENGTH}
      </S.InputLengthIndicator>
      <S.Button
        onClick={handleSend}
        aria-label="send message"
        tabIndex={preventFocus ? -1 : 0}
        data-testid="button"
        isHiddenTextInput={isHiddenTextInput}
        disabled={disableInput}
      >
        {botPersonality === BotPersonality.COMPANION
          ? <CompanionSendIcon width={21} height={21} />
          : <ExpertSendIcon width={21} height={21} />
        }
      </S.Button>
    </S.UserInputContainer>
  )
}
