import styled from 'styled-components'
import { RIBBON_BLUE, WHITE } from 'constants/colors'

const Header = styled.div<{ color: string; isFullScreenWidget: boolean; logoMarginTop: number }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${({ color }) => color || RIBBON_BLUE};
  padding: 0 20px;
  position: relative;
`

const NameWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 39px;
`

const Logo = styled.img<{ marginTop: number }>`
  width: 78px;
  height: 24px;
  margin-top: ${({ marginTop }) => marginTop}px;
  pointer-events: none;
`

const Name = styled.p`
  color: ${WHITE};
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  font-style: normal;
  margin: 6px 0px 4px 0px;
`

const ButtonsWrap = styled.div<{ marginTop: number }>`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: 4px;
`

const Button = styled.button`
  cursor: pointer;
  user-select: none;
  margin-left: 15px;
  border: none;
  animation-duration: 0.3s;
  animation-name: appearIn;
  background: transparent;
  padding: 0;
`

export { Header, NameWrap, Logo, Name, ButtonsWrap, Button }
