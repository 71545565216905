import React from 'react'
import { SettingsType } from 'models/SettingsType'
import * as S from './Popover.style'
import { useClickOutside } from 'hooks/useClickOutside'
import { CloseWidgetIcon } from 'icons/Icons'
import { COMET_BLUE } from 'constants/colors'

interface Props {
  isFullScreenWidget: boolean
  isMobile: boolean
  resizeWidget: (a: boolean) => void
  anchorEl: any
  parentEl: any
  onClose: () => void
  settings: SettingsType
  isSoundNotification: boolean
  handleSoundNotification: () => void
  resetChat: () => void
}

export const Popover: React.FC<Props> = ({
  isFullScreenWidget,
  isMobile,
  resizeWidget,
  anchorEl,
  parentEl,
  onClose,
  settings,
  isSoundNotification,
  handleSoundNotification,
  resetChat
}) => {
  const refPopover = useClickOutside(onClose, anchorEl)

  return (
    <S.Popover
      ref={refPopover}
      eleRect={anchorEl?.current?.getBoundingClientRect()}
      targetRect={parentEl?.current?.getBoundingClientRect()}>
      <S.Item onClick={resetChat} id="reset-chat-button" data-testid={'button-modal'}>
        <CloseWidgetIcon color={COMET_BLUE} />
        <p>Reset chat</p>
      </S.Item>
    </S.Popover>
  )
}
