import React, { Component, createRef } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransition'
import { getWidgetSettings, getPersistentMenuApi, getGreetingText,
  sendAction, sendGreetingEvent, getBotPersonality } from '../api/api'
import Container from '../components/containers/Container/Container'
import StartScreen from '../components/StartScreen'
import Button from '../components/Button'
import {
  checkIsRightButtonNeeded,
  fullScreenWidth,
  fullScreenHeight,
  transitionSettingsContainer,
  transitionSettingsLauncher,
  detectSiteLanguageOrDefault,
} from './config'
import { setAPIUrl } from '../config/config'

import './App.css'
import { CountUnreadMessagesProvider } from '../context/CountUnreadMessagesContext'
import { WebSocketsProviders } from '../context/WebSocketsContext'
import MessagesPreview from '../components/MessagesPreview'
import { WELCOME_SCREEN_OPEN, WIDGET_CLOSE, WIDGET_OPEN } from '../constants/actionsType'
import {
  WIDGET_WIDTH,
  WIDGET_HEIGHT,
  BUBBLE_WIDTH,
  BUBBLE_HEIGHT,
  EMBEDDED_WIDGET_WIDTH,
  EMBEDDED_WIDGET_HEIGHT,
} from '../constants/config'

import * as S from './App.style'
import { mockWidgetSettings } from '../config/mockSettings'
import { defaultBotPersonality } from '../config/defaultBotPersonality'
import { isEmbeddedSelector } from '../helper/isEmbeddedSelector'
import { embeddedWidgetSizeSelector } from '../helper/embeddedWidgetSizeSelector'

class App extends Component {
  constructor(props) {
    super(props)
    this.myRef = createRef()

    this.state = {
      user: null,
      init: false,
      messages: [],
      settings: {},
      authorized: false,
      openWidget: false,
      websiteLocation: '',
      settingsUpdated: false,
      showStartScreen: false,
      isFullScreenWidget: false,
      isEmbedded: isEmbeddedSelector(),
      greeting: null,
      device: {
        width: 0,
        height: 0,
      },
      textDisabled: true,
      isHiddenWidget: true,
      firstUnreadMessageId: null,
      isSoundNotification: true,
      isCustomBubble: false,
      isSomewhereWidgetOpen: false,
      eventId: null,
      inputTogglerValue: '',
      isProcessingGPTMessage: false,
      GPTMessagesQueue: [],
      botPersonality: null
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessage)
    this.initializeWidget()
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage)
  }

  componentDidUpdate(prevProps, prevState) {
    const { botId, websiteLocation, init, settingsUpdated, openWidget, showStartScreen } = this.state

    const { unreadMessagesList } = this.props

    const isShouldFrameResize =
      openWidget !== prevState.openWidget ||
      showStartScreen !== prevState.showStartScreen ||
      unreadMessagesList !== prevProps?.unreadMessagesList ||
      settingsUpdated !== prevState.settingsUpdated

    if (isShouldFrameResize) {
      const embeddedSize = embeddedWidgetSizeSelector()
      const isEmbedded = this.state.isEmbedded
      const widgetOffsetWidth = isEmbedded ? embeddedSize.width : this.myRef.current.offsetWidth + 'px'
      const widgetOffsetHeight = isEmbedded ? embeddedSize.height : this.myRef.current.offsetHeight + 'px'

      this.setFrameSize(widgetOffsetWidth, widgetOffsetHeight)
    }

    if (openWidget !== prevState.openWidget) {
      this.postMessageToggleWidget(openWidget)
    }

    if (websiteLocation && !init && settingsUpdated) {
      this.initialFunction()
    }

    if (openWidget !== prevState.openWidget && this.props.browserStorage?.chatId) {
      const actionType = openWidget ? WIDGET_OPEN : WIDGET_CLOSE
      sendAction(actionType, botId, this.props.browserStorage.chatId)
    }

    if (showStartScreen !== prevState.showStartScreen && showStartScreen && this.props.browserStorage?.chatId) {
      sendAction(WELCOME_SCREEN_OPEN, botId, this.props.browserStorage.chatId)
    }
  }

  initializeWidget() {
    // prettier-ignore
    window.parent.postMessage( //NOSONAR
      {
        type: 'widget-set-url',
      },
      '*',
    )
  }

  initialFunction() {
    const { settings, isMinimizedWidget, openByDefault, isEmbedded } = this.state
    const { chatId, isOpen, inputTogglerValue } = this.props.browserStorage
    const needUserInformation = settings.isLoginEmailInputEnabled || settings.isLoginNameInputEnabled
    const isEnabledStorage = settings.chatHistoryLevel !== 'NONE'
    const authorized = !needUserInformation || (chatId && isEnabledStorage) || isEmbedded

    if (inputTogglerValue) {
      this.setState({ inputTogglerValue })
    }
    if (isOpen !== null && chatId !== null && !isMinimizedWidget) {
      this.toggleBackground(isOpen)
      this.setState({ openWidget: isOpen, isHiddenWidget: !isOpen })
    }

    if (openByDefault && (!needUserInformation || isEmbedded)) {
      this.setState(
        {
          openWidget: true,
          init: true,
          isHiddenWidget: false,
          authorized,
        },
        () => {
          this.resizeWidget()
        },
      )
    } else if (this.state.greeting) {
      const popupDelayTimeout = setTimeout(() => {
        if (!this.state.openWidget) {
          this.setState({ popupWasShow: true }, () => {
            this.props.handleAddUnreadMessages(
              {
                isWidgetPopup: true,
                text: this.state.greeting?.greetingText,
                id: this.state.greeting?.hash,
              },
              this.state.websiteLocation,
            )
            sendGreetingEvent('SENT', this.state.botId, this.state.greeting.hash, chatId)
          })
        }
      }, this.state.greeting.delaySeconds * 1000)
      this.setState({
        init: true,
        authorized,
        popupDelayTimeout,
      })
    } else {
      this.setState({
        init: true,
        authorized,
      })
    }
  }

  handleMessage = event => {
    if (event.data.type === 'initialization') {
      this.configureWidget(event)
    }

    if (event.data.type === 'reset-chat-history') {
      this.resetChat()
    }

    if (event.data.type === 'clear-chat-history') {
      this.clearChat()
    }

    if (event.data.type === 'is-widget-open-at-some-tab') {
      this.setState({
        isSomewhereWidgetOpen: event.data.isWidgetOpen,
      })
    }

    if (event.data.type === 'widget-resize-screen') {
      this.setState(
        {
          screenSize: event.data.screenSize,
        },
        () => this.resizeWidget(this.state.isFullScreenWidget),
      )
    }

    if (event.data.type === 'remove-event-listeners') {
      window.parent.postMessage({ type: 'remove-event-listeners' }, this.state.websiteLocation)
    }
  }

  configureWidget = event => {
    const language = detectSiteLanguageOrDefault(event.data.url)
    const chatId = event.data.storage?.chatId

    this.props.initializeStorage(event.data.url, event.data.storage)
    setAPIUrl(event.data.serverUrl)

    const promises = [
      getWidgetSettings(event.data.botId, chatId, language),
      getGreetingText(event.data.botId, event.data.attributes, language, chatId),
    ]

    if (event.data.botId && chatId) {
      promises.push(getBotPersonality(event.data.botId, chatId))
    }

    Promise.all(promises).then(([settings = mockWidgetSettings, greeting, personality = defaultBotPersonality]) => {
      this.props.updateBrowserStorage({ botId: event.data.botId, chatId: chatId })
      this.setInputField(settings?.isComposerInputEnabled)

      if (event.data.isTestMode) {
        this.setBrowserTabName(settings?.botName, event.data.url)
      }

      this.setState({
        botId: event.data.botId,
        settingsUpdated: true,
        attributes: event.data.attributes,
        isSoundNotification: settings?.widgetSettings?.doEnableSoundNotifications,
        firstUnreadMessageId: event.data.storage.firstUnreadMessageId,
        isCustomBubble: event.data.isCustomBubble,
        isMobile: event.data.isMobile,
        user: this.state.user || { language },
        websiteLocation: event.data.url,
        device: event.data.device,
        isMinimizedWidget: event.data.isMinimizedWidget,
        greeting,
        settings,
        openByDefault: event.data.open || this.state.isEmbedded,
        isSomewhereWidgetOpen: event.data.isWidgetOpen,
        screenSize: event.data.screenSize,
        botPersonality: personality.botPersonality
      })
    })
  }

  resetChat = () => {
    this.setState(
      {
        chatId: null,
        openWidget: false,
        authorized: false,
        showStartScreen: false,
        isFullScreenWidget: false,
        user: null,
        messages: [],
      },
      () => {
        this.resizeWidget(false)

        if (this.state.websiteLocation) {
          window.parent.postMessage({ type: 'reload-page' }, this.state.websiteLocation)
        }
      },
    )
  }

  clearChat = () => {
    this.setState(
      {
        chatId: null,
        openWidget: false,
        authorized: false,
        showStartScreen: false,
        isFullScreenWidget: false,
        user: null,
        messages: [],
      },
      () => {
        this.resizeWidget(false)
        if (this.state.websiteLocation) {
          window.parent.postMessage({ type: 'clear-storage' }, this.state.websiteLocation)
        }
      },
    )
  }

  setFrameSize(newWidth, newHeight) {
    const { showStartScreen, isMobile, websiteLocation } = this.state

    window.parent.postMessage(
      {
        type: 'widget',
        width: newWidth,
        height: newHeight,
        needDelay: showStartScreen && isMobile,
      },
      websiteLocation,
    )
  }

  postMessageToggleWidget(isWidgetOpen) {
    window.parent.postMessage(
      {
        type: 'toggle-widget',
        isWidgetOpen,
      },
      this.state.websiteLocation,
    )
  }

  updateWidget = (botId, language) => {
    getWidgetSettings(botId, null, language).then(settings => {
      if (!settings?.data) return
      this.setState({
        settings,
        isSoundNotification: settings?.widgetSettings?.doEnableSoundNotifications,
      })
    })
  }

  setPersistentMenu = (botId, chatId) => {
    if (botId && chatId) {
      getPersistentMenuApi(botId, chatId)
        .then(persistentMenu => {
          this.setState({ persistentMenu })
        })
        .catch(console.error)
    }
  }

  setInputField = textDisabled => {
    if (this.state.textDisabled !== textDisabled) {
      this.setState({ textDisabled })
    }
  }

  setTextTogglerValue = value => {
    this.props.updateBrowserStorage({ inputTogglerValue: value })
    this.setState({ inputTogglerValue: value })
  }

  openStartScreen = () => {
    this.props.updateBrowserStorage({ isOpen: false })
    this.toggleBackground(false)
    this.props.handleClearUnreadMessages(this.state.websiteLocation)
    this.setState({
      isHiddenWidget: false,
      showStartScreen: true,
    })
  }
  hideStartScreen = () => {
    this.setState({ showStartScreen: false })
  }

  toggleAuthorized = () => {
    this.props.updateBrowserStorage({ isOpen: !this.state.openWidget })
    this.setState(
      {
        openWidget: !this.state.openWidget,
        isHiddenWidget: !this.state.openWidget ? false : this.state.isHiddenWidget,
      },
      () => {
        if (this.state.isFullScreenWidget) {
          this.resizeWidget(false)
        }
        this.props.handleClearUnreadMessages(this.state.websiteLocation)
        this.toggleBackground(this.state.openWidget)
      },
    )
  }

  toggleUnauthorized = () => {
    const { showStartScreen } = this.state
    if (showStartScreen) {
      this.hideStartScreen()
    } else {
      this.props.updateBrowserStorage({ isOpen: false })
      this.toggleBackground(false)
      this.openStartScreen()
    }
  }

  resizeWidget = isFullScreenWidget => {
    const widgetOffsetWidth = this.state.openWidget ? (this.state.isEmbedded ?
      EMBEDDED_WIDGET_WIDTH : WIDGET_WIDTH) : BUBBLE_WIDTH

    const widgetOffsetHeight = this.state.openWidget ? (this.state.isEmbedded ?
      EMBEDDED_WIDGET_HEIGHT : WIDGET_HEIGHT) : BUBBLE_HEIGHT

    const width = isFullScreenWidget ? fullScreenWidth : widgetOffsetWidth
    const height = isFullScreenWidget ? fullScreenHeight : widgetOffsetHeight

    this.setState(
      {
        isFullScreenWidget,
      },
      () => {
        window.parent.postMessage(
          {
            type: 'resize-widget',
            width,
            height,
            isFullScreenWidget: this.state.isFullScreenWidget,
          },
          this.state.websiteLocation,
        )
      },
    )
  }

  toggleWidget = () => {
    const { authorized, settings, popupDelayTimeout, isEmbedded } = this.state
    clearTimeout(popupDelayTimeout)

    const needUserInformation = settings.isLoginNameInputEnabled || settings.isLoginEmailInputEnabled || !isEmbedded
    if (needUserInformation && !authorized) {
      this.toggleUnauthorized()
    } else {
      this.toggleAuthorized()
    }
  }

  toggleBackground = isBackgroundEnable => {
    const { settings } = this.state
    const isEnabledStorage = settings.chatHistoryLevel !== 'NONE'
    if (!settings.isBackgroundBlurred) return
    if (!isBackgroundEnable && isEnabledStorage) {
      this.setState({ messages: [] })
    }

    window.parent.postMessage(
      {
        type: 'widget-background',
        background: isBackgroundEnable,
      },
      this.state.websiteLocation,
    )
  }

  closeWidget = () => {
    this.setState(
      {
        openWidget: false,
      },
      () => {
        this.toggleBackground(this.state.openWidget)
        this.props.updateBrowserStorage({ isOpen: this.state.openWidget })
        if (this.state.isFullScreenWidget) {
          this.resizeWidget(false)
        }
      },
    )
  }

  updateMessages = (newMessages, callback, isOldMessages) => {
    if (newMessages && newMessages.length > 0) {
      if (isOldMessages) {
        return this.setState(
          prevState => ({ messages: newMessages.concat(prevState.messages) }),
          () => {
            if (callback) callback()
          },
        )
      }
      return this.setState(
        prevState => ({ messages: prevState.messages.concat(newMessages) }),
        () => {
          if (callback) callback()
        },
      )
    }
  }

  updateUserData = user => {
    user.language = detectSiteLanguageOrDefault(this.state.websiteLocation)
    this.props.updateBrowserStorage({ isOpen: true })
    this.setState(
      {
        user,
        showStartScreen: false,
        openWidget: true,
        authorized: true,
      },
      () => {
        this.toggleBackground(true)
      },
    )
  }

  submitPopup = () => {
    const chatId = this.props.browserStorage.chatId
    this.props.handleClearUnreadMessages(this.state.websiteLocation)

    return sendGreetingEvent('OPENED', this.state.botId, this.state.greeting.hash, chatId)
      .then(data => this.updateEventId(data?.id))
      .then(() => this.toggleWidget())
  }

  updateGreeting = greeting => {
    this.setState({ greeting })
  }

  handleHiddenWidget = () => {
    this.setState({ isHiddenWidget: true }, this.toggleWidget)
  }

  handleViewWidget = () => {
    this.setState({ isHiddenWidget: false }, this.toggleWidget)
  }

  setFirstUnreadMessageId = message => {
    this.setState({ firstUnreadMessageId: message }, () => {
      this.props.updateBrowserStorage({ firstUnreadMessageId: message })
    })
  }

  handleSoundNotification = () => {
    this.setState({ isSoundNotification: !this.state.isSoundNotification })
  }

  replaceMessages = messages => {
    this.setState({ messages })
  }

  updateEventId = eventId => {
    this.setState({ eventId })
  }

  updatePopupWasShow = () => {
    this.setState({ popupWasShow: false })
  }

  setBrowserTabName = (botName, websiteLocation) => {
    window.parent.postMessage(
      {
        type: 'set-browser-tab-name',
        botName,
      },
      websiteLocation,
    )
  }

  setIsProcessingGPTMessage = isProcessingGPTMessage => {
    this.setState({ isProcessingGPTMessage })
  }

  setGPTMessagesQueue = GPTMessageChunk => {
    this.setState(({ GPTMessagesQueue }) => ({ GPTMessagesQueue: GPTMessagesQueue.concat(GPTMessageChunk) }))
  }

  resetGPTMessagesQueue = () => {
    this.setState({ GPTMessagesQueue: [] })
  }

  setBotPersonality = botPersonality => {
    this.setState({ botPersonality })
  }

  render() {
    const {
      init,
      user,
      botId,
      device,
      isMobile,
      messages,
      settings,
      greeting,
      openWidget,
      authorized,
      attributes,
      textDisabled,
      websiteLocation,
      settingsUpdated,
      showStartScreen,
      isFullScreenWidget,
      persistentMenu,
      popupWasShow,
      isHiddenWidget,
      firstUnreadMessageId,
      isSoundNotification,
      isCustomBubble,
      openByDefault,
      isSomewhereWidgetOpen,
      eventId,
      screenSize,
      inputTogglerValue,
      GPTMessagesQueue,
      botPersonality,
      isEmbedded
    } = this.state

    const isWidgetOpened = !authorized ? !showStartScreen : !openWidget
    const isPopupsNeeded = init && !showStartScreen && !openWidget
    const isButtonNeeded = checkIsRightButtonNeeded(device, openWidget, isFullScreenWidget) && !isEmbedded

    return (
      <>
        {settingsUpdated && (
          <S.App ref={this.myRef} isFullScreenWidget={isFullScreenWidget} isEmbedded={isEmbedded}>
            <BrowserRouter basename="/">
              <CountUnreadMessagesProvider isSoundNotification={isSoundNotification}>
                <WebSocketsProviders
                  user={user}
                  botId={botId}
                  eventId={eventId}
                  authorized={authorized}
                  messages={messages}
                  setIsProcessingGPTMessage={this.setIsProcessingGPTMessage}
                  GPTMessagesQueue={GPTMessagesQueue}
                  setGPTMessagesQueue={this.setGPTMessagesQueue}
                  resetGPTMessagesQueue={this.resetGPTMessagesQueue}
                  greeting={greeting}
                  settings={settings}
                  openWidget={openWidget}
                  attributes={attributes}
                  popupWasShow={popupWasShow}
                  websiteLocation={websiteLocation}
                  isHiddenWidget={isHiddenWidget}
                  openByDefault={openByDefault}
                  isSomewhereWidgetOpen={isSomewhereWidgetOpen}
                  closeWidget={this.closeWidget}
                  setInputField={this.setInputField}
                  setTextTogglerValue={this.setTextTogglerValue}
                  updateMessages={this.updateMessages}
                  updateGreeting={this.updateGreeting}
                  setPersistentMenu={this.setPersistentMenu}
                  updateWidget={this.updateWidget}
                  replaceMessages={this.replaceMessages}
                  firstUnreadMessageId={firstUnreadMessageId}
                  updatePopupWasShow={this.updatePopupWasShow}
                  setFirstUnreadMessageId={this.setFirstUnreadMessageId}
                  setBotPersonality={this.setBotPersonality}
                  isEmbedded={isEmbedded}
                >
                  <>
                    {openWidget && (
                      <ReactCSSTransitionGroup {...transitionSettingsContainer}>
                        <Container
                          device={device}
                          isMobile={isMobile}
                          messages={messages}
                          isProcessingGPTMessage={this.state.isProcessingGPTMessage}
                          setIsProcessingGPTMessage={this.setIsProcessingGPTMessage}
                          GPTMessagesQueue={GPTMessagesQueue}
                          resetGPTMessagesQueue={this.resetGPTMessagesQueue}
                          isHiddenWidget={isHiddenWidget}
                          settings={settings}
                          persistentMenu={persistentMenu}
                          isSoundNotification={isSoundNotification}
                          parentURL={websiteLocation}
                          textDisabled={textDisabled}
                          inputTogglerValue={inputTogglerValue}
                          resizeWidget={this.resizeWidget}
                          toggleWidget={this.toggleWidget}
                          updateMessages={this.updateMessages}
                          handleHiddenWidget={this.handleHiddenWidget}
                          isFullScreenWidget={isFullScreenWidget}
                          firstUnreadMessageId={firstUnreadMessageId}
                          screenSize={screenSize}
                          setFirstUnreadMessageId={this.setFirstUnreadMessageId}
                          handleSoundNotification={this.handleSoundNotification}
                          botPersonality={botPersonality}
                          setBotPersonality={this.setBotPersonality}
                          isEmbedded={isEmbedded}
                          resetChat={this.resetChat}
                        />
                      </ReactCSSTransitionGroup>
                    )}
                    {showStartScreen && settings && (
                      <ReactCSSTransitionGroup {...transitionSettingsContainer}>
                        <StartScreen
                          greeting={greeting}
                          device={device}
                          settings={settings}
                          updateUserData={this.updateUserData}
                          popupWasShow={popupWasShow}
                        />
                      </ReactCSSTransitionGroup>
                    )}
                    {!isEmbedded && (
                      <ReactCSSTransitionGroup {...transitionSettingsLauncher}>
                        <S.ButtonWrap>
                          <MessagesPreview
                            device={device}
                            greeting={greeting}
                            authorized={authorized}
                            settings={settings}
                            messages={messages}
                            websiteLocation={websiteLocation}
                            submitPopup={this.submitPopup}
                            isPopupsNeeded={isPopupsNeeded && !isCustomBubble && isButtonNeeded}
                          />
                          <Button
                            eventId={eventId}
                            botId={botId}
                            isHiddenWidget={isHiddenWidget}
                            greeting={greeting}
                            settings={settings}
                            messages={messages}
                            authorized={authorized}
                            showStartScreen={showStartScreen}
                            isWidgetOpened={isWidgetOpened}
                            isButtonNeeded={!isCustomBubble && isButtonNeeded}
                            popupWasShow={popupWasShow}
                            toggleWidget={this.toggleWidget}
                            handleViewWidget={this.handleViewWidget}
                            updateEventId={this.updateEventId}
                          />
                        </S.ButtonWrap>
                      </ReactCSSTransitionGroup>
                    )}
                  </>
                </WebSocketsProviders>
              </CountUnreadMessagesProvider>
            </BrowserRouter>
          </S.App>
        )}
      </>
    )
  }
}

export default App
